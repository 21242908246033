const notFoundRoutes = [
    {
        path:       "/un-authorized",
        name:       "UnAuthorized",
        component:  () => import(/* webpackChunkName: "UnAuthorized" */ "@/views/UnAuthorized.vue"),
        meta: {
            authentication: false
        }
    },
    {
        path:       "/server-error",
        name:       "ServerError",
        component:  () => import(/* webpackChunkName: "ServerError" */ "@/views/ServerError.vue"),
        meta: {
            authentication: false
        }
    },
    {
        path:       "/404",
        name:       "NotFound",
        component:  () => import(/* webpackChunkName: "notFound" */ "@/views/NotFound.vue"),
        meta: {
            authentication: false
        }
    },
    {
        path:       "/many-requests",
        name:       "TooManyRequests",
        component: () => import(/* webpackChunkName: "TooManyRequests" */ "@/views/TooManyRequests.vue"),
    },
    {
        path:       "*",
        redirect:   "/404"
    }
];

export default notFoundRoutes;