import Navbar from "@/layout/Navbar.vue";
import Sidebar from "@/layout/Sidebar.vue";

const publicToolRoutes = [
    {
        path: "/eka-search-form",
        name: "SearchWithEka",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "SearchWithEka" */ "@/views/PublicTools/SearchWithEka.vue"
                ),
        },
        props: { main: route => ({ ekaQuery: Number(route.query.id)}) },
        meta: {
            authentication: false,
        },
    },

    {
        path: "/ota-statistics-search-form",
        name: "OtaStatisticsSearch",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "OtaStatisticsSearch" */ "@/views/PublicTools/OtaStatisticsSearch.vue"
                ),
        },
        meta: {
            authentication: false,
        },
    },

    {
        path: "/document-validation",
        name: "DocumentValidation",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "DocumentValidation" */ "@/views/PublicTools/DocumentValidation.vue"
                ),
        },
        meta: {
            authentication: false,
        },
    },

    {
        path: "/serial-search-form",
        name: "SerialSearchForm",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "SerialSearchForm" */ "@/views/PublicTools/SerialSearchForm.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },
];

export default publicToolRoutes