import api from "./api";

const URLS = {
    login: "login",
    logout: "logout",
    taxisNet: "taxisnet/authenticate",
    forgot: "forgot-password",
    reset: "reset-password",
    activation: "user/activate",
    taxisnet: "taxisnet"
};

const CONFIG = {
    showToast: true
};

export const loginUser = payload => {
    return api.post(URLS.login, payload);
};

export const logOutUser = () => {
    return api.delete(URLS.logout);
};

export const authWithTaxis = () => {
    return api.get(URLS.taxisNet);
};

export const activateAccount = (payload) => {
    return api.get(`${URLS.activation}/${payload.id}/${payload.token}`)
};

export const forgotPassword = paylaod => {
    return api.post(URLS.forgot, paylaod);
};

export const resetPassword = paylaod => {
    return api.post(URLS.reset, paylaod);
};

export const taxisFirstStep = payload => {
    return api.post(`${URLS.taxisNet}/authenticate`, payload);
};