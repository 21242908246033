import Navbar from "@/layout/Navbar.vue";
import Sidebar from "@/layout/Sidebar.vue";

const emailsRoutes = [
    {
        path: "/send-mass-emails",
        name: "SendMassEmails",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "SendMassEmails" */ "@/views/MailManagement/SendMassEmails.vue"
                ),
        },
    },
    {
        path: "/send-mass-emails-with-link",
        name: "SendMassEmailsWithLink",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "SendMassEmailsWithLink" */ "@/views/MailManagement/SendMassEmailsWithLink.vue"
                ),
        },
    },
];

export default emailsRoutes