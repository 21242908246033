<template>
    <v-container fluid class="login">
        <div class="overlay"></div>

        <div class="login__box shadow">
            <div class="login__header">
                <div class="mt-2 mb-4">
                    <h1 class="login-form-title">
                        {{ $t( `general.appTitle` ) }}
                    </h1>
                </div>
            </div>

            <div class="login__content">
                <base-info-alert color="primary">
                    {{ $t(`auth.reset.info`) }}
                </base-info-alert>
            </div>
            
            <div class="login__form mt-4">
                <v-form ref="loginForm" full-width>
                    <general-input
                        :input-value.sync="username"
                        :is-valid.sync="isUsernameValid"
                        :label="$t('auth.username')"
                    ></general-input>
                    <password-input
                        v-model="password"
                        :is-valid.sync="isPasswordValid"
                        :form-item="passwordItem"
                    ></password-input>
                    <password-confirmation
                        :password.sync="passwordConfirmation"
                        :is-valid.sync="isConfirmValid"
                        :new-password="password"
                        :label="$t(`auth.confirmPassword`)"
                    ></password-confirmation>

                    <v-btn
                        block
                        depressed
                        large
                        color="primary"
                        type="submit"
                        :disabled="isValid"
                        :loading="resetStatus_Pending"
                        @click.prevent="reset()"
                    >
                        {{ $t("auth.reset.resetButton") }}
                    </v-btn>
                </v-form>
            </div>
            <div class="login__form-extra">
                <p class="ma-0">
                    {{ $t(`auth.reset.resetRedirection`) }}
                </p>
                <v-btn
                    text
                    depressed
                    color="primary"
                    :to="{name: 'Login'}"
                >
                    {{ $t("auth.forgot.loginRedirectionBtn") }}
                </v-btn>
            </div>
        </div>
  </v-container>
</template>

<script>
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from  "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { resetPassword } from "@/api/authApi.js";

import GeneralInput from "@/components/General/FormInputs/GeneralInput.vue";
import PasswordInput from "@/components/General/FormInputs/PasswordInput.vue";
import PasswordConfirmation from "@/components/General/FormInputs/PasswordConfirmation.vue";

import { MessageSuccess } from "@/api/servises/ToastNotification";
export default {
    name:   "Reset",

    props: {
        token: {
            required: true,
            default: null,
        }
    },

    components: {
        GeneralInput,
        PasswordInput,
        PasswordConfirmation,
    },

    data() {
        return {
            username: "",
            password: null,
            passwordConfirmation: null,
            isUsernameValid: true,
            isPasswordValid: true,
            isConfirmValid: true,
            passwordItem: {
                required: true,
                label: "password"
            },
            resetStatus: apiStatus.Idle
        }
    },

    computed: {
        isValid() {
            if (!this.isUsernameValid && !this.isPasswordValid && !this.isConfirmValid) {
                return false
            }
            return true
        },

        ...apiStatusComputed("resetStatus"),
    },

    methods: {
        async reset() {
            this.resetStatus = apiStatus.Pending;

            const payload = {
                username: this.username,
                password: this.password,
                password_confirmation: this.passwordConfirmation,
                token: this.token
            }
            
            const { response, error } = await withAsync(resetPassword, payload);

            if (error) {
                this.resetStatus = apiStatus.Error;
                return;
            }

            this.resetStatus = apiStatus.Success;
            MessageSuccess(response.data.metadata.response_message)
            
            this.$router.push({name: "Login"});
        },
    },

    created() {
        this.apiStatus = apiStatus;
    }
}
</script>

<style lang="scss" scoped>
 h1 {
    line-height: 1em;
}

h1 span {
    display: block;
    text-transform: initial;
}

span.subtitle {
    color: var(--v-black-base);
    display: block;
    font-size: 1.2rem;
}
.header {
    display: flex;
    align-items: center;
}

.logo img {
    height: 90px;
    object-fit: contain;
}
.login--wrapper-overlay {
    background-color: rgba(0,0,0, .6);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.login-form-title {
    color: var(--v-primary-base);
}

.forgot-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.2em;
}
</style>