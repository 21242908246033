import Navbar from "@/layout/Navbar.vue";
import Sidebar from "@/layout/Sidebar.vue";

const predefinedListRoutes = [
    {
        path: "/ekacodes",
        name: "Ekacodes",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "Ekacodes" */ "@/views/Ekacodes/EkacodesList.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/ekacode/:id",
        name: "Ekacode",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "Ekacode" */ "@/views/Ekacodes/Ekacode.vue"
                ),
        },
        meta: {
            authentication: true,
        },

        props: { main: true },
    },

    // {
    //     path: "/ekacode-create-edit/:id?",
    //     name: "EkacodeCreateEdit",
    //     components: {
    //         topbar: Navbar,
    //         sidebar: Sidebar,
    //         main: () =>
    //             import(
    //                 /* webpackChunkName: "EkacodeCreateEdit" */ "@/views/Ekacodes/EkacodeCreateEdit.vue"
    //             ),
    //     },
    //     props: { main: true },
    //     meta: {
    //         authentication: false,
    //     },
    // },

    {
        path: "/stacodes",
        name: "Stacodes",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "Stacodes" */ "@/views/Stacodes/StacodesList.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/stacode/:id",
        name: "Stacode",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "Stacode" */ "@/views/Stacodes/Stacode.vue"
                ),
        },
        meta: {
            authentication: true,
        },

        props: { main: true },
    },

    // {
    //     path: "/stacode-create-edit/:id?",
    //     name: "StacodeCreateEdit",
    //     components: {
    //         topbar: Navbar,
    //         sidebar: Sidebar,
    //         main: () =>
    //             import(
    //                 /* webpackChunkName: "StacodeCreateEdit" */ "@/views/Stacodes/StacodeCreateEdit.vue"
    //             ),
    //     },
    //     props: { main: true },
    //     meta: {
    //         authentication: false,
    //     },
    // },

    {
        path: "/ya-members",
        name: "YaMembers",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "YaMembers" */ "@/views/YaMembers/YaMembersList.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/ya-member/:id",
        name: "YaMember",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "YaMember" */ "@/views/YaMembers/YaMember.vue"
                ),
        },
        meta: {
            authentication: true,
        },

        props: { main: true },
    },

    {
        path: "/ya-member-create/:id?",
        name: "YaMemberCreate",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "YaMemberCreate" */ "@/views/YaMembers/YaMemberCreate.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/seds",
        name: "Seds",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "Seds" */ "@/views/Seds/SedList.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/sed/:id",
        name: "Sed",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "Sed" */ "@/views/Seds/Sed.vue"
                ),
        },
        meta: {
            authentication: true,
        },

        props: { main: true },
    },

    {
        path: "/sed-create/:id?",
        name: "SedCreate",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "SedCreate" */ "@/views/Seds/SedCreate.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: true,
        },
    },
];

export default predefinedListRoutes;