import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import preset from "./defaultPreset/preset/index";

Vue.use(Vuetify);

export default new Vuetify({
    preset,
    icons: {
        iconfont: "fa",
    },
    theme: {
        options: {
            customProperties: true,
            variations: true, // creates all the shades
        },
    },
});
