import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// General settings
const OPTIONS = {
    ransition:      "Vue-Toastification__fade",
    maxToasts:      1,
    newestOnTop:    true
};

let toast_options = {
    timeout:                4000,
    hideProgressBar:        true,
    icon:                   false,
    closeOnClick:           true,
    pauseOnFocusLoss:       true,
    pauseOnHover:           true,
    draggable:              true,
    draggablePercent:       0.6,
    position:               "bottom-right",
    showCloseButtonOnHover: true,
};

Vue.use(Toast, OPTIONS);

export const MessageSuccess = message => {
    Vue.$toast.success(message, toast_options);
};

export const MessageError = message => {
    Vue.$toast.error(message, toast_options);
};