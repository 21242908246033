import Navbar from "@/layout/Navbar.vue";
import Sidebar from "@/layout/Sidebar.vue";

const foodCompanyRoutes = [
    {
        path: "/food-companies",
        name: "FoodCompanies",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "FoodCompanies" */ "@/views/FoodCompany/FoodCompanies.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/food-waste-statements/:id?",
        name: "FoodWasteStatements",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "FoodWasteReports" */ "@/views/FoodCompany/FoodWasteReports.vue"
                ),
        },
        props: {main: true},
        meta: {
            authentication: true,
        },
    },

    {
        path: "/food-report-preview/:id?",
        name: "FoodReportLog",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "FoodWasteReportLog" */ "@/views/FoodCompany/FoodWasteReportLog.vue"
                ),
        },
        props: {main: true},
        meta: {
            authentication: true,
        },
    },

    {
        path: "/food-report-log/:id?",
        name: "FoodWasteReportPreview",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "FoodWasteReportPreview" */ "@/views/FoodCompany/FoodWasteReportPreview.vue"
                ),
        },
        props: {main: true},
        meta: {
            authentication: true,
        },
    },
];

export default foodCompanyRoutes;