<template>
    <v-alert
        dense
        text
        :color="color"
        border="left"
        :icon="icon"
    >
        <slot></slot>
    </v-alert>
</template>

<script>
export default {
    name: "BaseInfoAlert",

    props: {
        color: {
            required: false,
            type: String,
            default: "info lighten-1"
        },
        icon: {
            required: false,
            type: String,
            default: "fa-solid fa-info"
        }
    }
}
</script>

<style lang="scss" scoped>

</style>