import store from "@/store";

import Navbar from "@/layout/Navbar.vue";
import Sidebar from "@/layout/Sidebar.vue";

/**
 *  beforeEnter Navigation guards
 *  hasAuth
 */
const hasAuth = (to, from, next) => {
    let isLoggedIn = store.getters["auth/isLoggedIn"];
    if (to.params.id && !isLoggedIn) {
        next("/login");
    }
    next();
};

const companyRoutes = [
    {
        path: "/companies",
        name: "Companies",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "Companies" */ "@/views/Company/Companies.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },
    {
        path: "/company/:id",
        name: "Company",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "Company" */ "@/views/Company/Company.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: true,
        },
    },
    {
        path: "/self-deactivate/:id",
        name: "SelfDeactivate",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "SelfDeactivate" */ "@/views/Company/SelfDeactivate.vue"
                ),
        },
        props: {
            main: (route) => ({
                id: Number(route.params.id),
                canDeactivate: route.query.deactivate == "true",
                role: route.query.role,
            }),
        },
        meta: {
            authentication: false,
        },
    },

    {
        path: "/confirm-deactivate",
        name: "ConfirmDeactivate",
        components: {
            main: () =>
                import(
                    /* webpackChunkName: "ConfirmDeactivate" */ "@/views/Company/ConfirmDeactivate.vue"
                ),
        },
        props: {
            main: (route) => ({
                id: Number(route.query.id),
                expiration: route.query.expiration,
                mac: route.query.mac,
                role: route.query.role,
            }),
        },
        meta: {
            authentication: false,
        },
    },
    {
        path: "/company-log/:id",
        name: "CompanyPreviewLog",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "CompanyPreviewLog" */ "@/views/Company/CompanyPreviewLog.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: true,
        },
    },
    {
        path: "/company-create-edit/:id?",
        name: "CompanyCreateEdit",
        beforeEnter: hasAuth,
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "CompanyCreateEdit" */ "@/views/Company/CompanyCreateEdit.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: false,
        },
    },
    {
        path: "/company-settlements/:id",
        name: "CompanySettlements",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "CompanySettlements" */ "@/views/Company/CompanySettlements.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: false,
        },
    },
    {
        path: "/waste-report/:id?",
        name: "WasteReport",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "WasteReport" */ "@/views/Company/WasteReport.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: false,
        },
    },
    {
        path: "/waste-report-preview/:id",
        name: "WasteReportPreview",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "WasteReportPreview" */ "@/views/Company/WasteReportPreview.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: false,
        },
    },
    {
        path: "/waste-report-log/:id",
        name: "WasteReportLog",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "WasteReportLog" */ "@/views/Company/WasteReportLog.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: false,
        },
    },

    {
        path: "/e-paravolo/:statementId/:paravoloId",
        name: "EParavoloStatement",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "EParavoloStatement" */ "@/views/Company/EParavoloStatement.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: false,
        },
    },

    {
        path: "/inactive-companies",
        name: "InactiveCompanies",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "InactiveCompanies" */ "@/views/Company/InactiveCompanies.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/deleted-companies",
        name: "DeletedCompanies",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "DeletedCompanies" */ "@/views/Company/DeletedCompanies.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/deactivated-companies",
        name: "DeactivatedCompanies",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "DeactivatedCompanies" */ "@/views/Company/DeactivatedCompanies.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },
];

export default companyRoutes;
