<template>
    <v-row class="justify-center">
        <v-col cols="12" lg="10" xl="8" class="py-0">
            <v-skeleton-loader
                type="card-heading, list-item@3"
            ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" lg="10" xl="8" class="py-0 mb-6">
            <v-skeleton-loader
                type="card-heading, list-item@6, actions"
            ></v-skeleton-loader>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "BaseFormSkeleton",
}
</script>