import Navbar from "@/layout/Navbar.vue";
import Sidebar from "@/layout/Sidebar.vue";

const adminRoutes = [
    {
        path: "/administrators",
        name: "AdminsList",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "AdminsList" */ "@/views/Admin/AdminsList.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },
    {
        path: "/profile/:id",
        name: "Profile",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "Profile" */ "@/views/Admin/Profile.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: true,
        },
    },
    {
        path: "/edit-profile/:id?",
        name: "EditProfile",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "EditProfile" */ "@/views/Admin/EditProfile.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: true,
        },
    },
    {
        path: "/administrator-roles",
        name: "AdminRoles",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "AdminRoles" */ "@/views/AdminRolesList.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/administrator-role/:roleId",
        name: "AdminRole",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "AdminRole" */ "@/views/AdminRole.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/role-create-edit/:roleId?",
        name: "AdminRoleCreateEdit",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "AdminRoleCreateEdit" */ "@/views/AdminRoleCreateEdit.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/settings",
        name: "Settings",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "AdminSettings" */ "@/views/AdminSettings.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/settings-edit/:id",
        name: "AdminSettingsEdit",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "AdminSettingsEdit" */ "@/views/AdminSettingsEdit.vue"
                ),
        },
        meta: {
            authentication: true,
        },

        props: { main: true },
    },

    {
        path: "/files-list",
        name: "PublicFilesList",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "PublicFilesList" */ "@/views/PublicFilesList.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/add-public-file",
        name: "PublicFilesAdd",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "PublicFilesAdd" */ "@/views/PublicFilesAdd.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/dashboard",
        name: "Dashboard",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "Dashboard" */ "@/views/Dashboard/Dashboard.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },
];

export default adminRoutes