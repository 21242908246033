<template>
    <v-app>
        <router-view></router-view>
  </v-app>
</template>

<script>
export default {
    name: "App",

    data: () => ({ drawer: null }),
    
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url("./styles/common.scss");
@import url("./styles/transitions.scss");
</style>
