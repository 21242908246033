<template>
    <v-menu offset-y min-width="160" transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
            <v-btn :text="text" depressed :color="color" v-bind="attrs" v-on="on">
                <slot name="btnLabel">
                    <v-icon small>
                        {{ icon }}
                    </v-icon>
                </slot>
            </v-btn>
        </template>
        <v-card flat>
            <v-card-text class="pa-1">
                <slot></slot>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: "BaseUserActions",

    props: {
        color: {
            type: String,
            required: false,
            default: "primary",
        },
        icon: {
            type: String,
            required: false,
            default: "fas fa-ellipsis-vertical",
        },
        text: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
};
</script>
