const setOta = ( state, data ) => {
    state.isOta = data;
};

const setHasRegion = ( state, data ) => {
    state.hasRegion = data;
};

const setHasStorage = ( state, data ) => {
    state.hasStorage = data;
};

const setHasCollection = ( state, data ) => {
    state.hasCollection = data;
};

const setSed = ( state, data ) => {
    state.hasSed = data;
};

const setMunicipality = ( state, data ) => {
    state.hasMunicipality = data;
};

const setFoodCompany = ( state, data ) => {
    state.isFoodCompany = data;
};

const setCompany = ( state, data ) => {
    state.isCompany = data;
};

export default {
    setOta,
    setHasRegion,
    setHasStorage,
    setHasCollection,
    setSed,
    setMunicipality,
    setFoodCompany,
    setCompany
}