import api from "./api";

const URLS = {
    notification: "notification",
};

export const getNotification = id => {
    return api.get(`${URLS.notification}/${id}`);
};

export const getNotifications = params => {
    return api.get(`${URLS.notification}`, { params });
};