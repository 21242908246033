<template>
    <div>
        <v-dialog v-model="confirmDialog" persistent max-width="700">
            <v-card outlined>
                <v-toolbar
                    color="primary"
                    dark
                    flat
                    class="d-flex justify-center"
                >
                    <slot name="title"></slot>
                </v-toolbar>
                <v-card-text class="d-flex justify-center">
                    <slot name="content"></slot>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeDialog()">
                        {{ $t(`base.submitModal.close`) }}
                    </v-btn>
                    <slot name="confirmButton"></slot>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

//
<script>
export default {
    name: "SubmitEmailModal",

    props: {
        open: {
            required: true,
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            confirmDialog: false,
        };
    },

    methods: {
        closeDialog() {
            this.confirmDialog = false;
            this.$emit("update:open", false);
        },
    },

    watch: {
        confirmDialog(newValue) {
            if (newValue == false) {
                this.$emit("update:open", false);
            }
        },
        open(newValue) {
            if (newValue == true) {
                this.confirmDialog = newValue;
            }
            this.confirmDialog = newValue;
        },
    },
};
</script>

<style lang="scss" scoped></style>
