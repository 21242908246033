import store from "@/store";

import Navbar from "@/layout/Navbar.vue";
import Sidebar from "@/layout/Sidebar.vue";

/**
 *  beforeEnter Navigation guards 
 *  ihasAuth
 */
 const hasAuth = (to, from, next) => {
    let isLoggedIn = store.getters["auth/isLoggedIn"];
    if (to.params.id && !isLoggedIn) {
        return next("/login");
    }
    next();
    
};

const settlementRoutes = [
    {
        path: "/settlements",
        name: "Settlements",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "Settlements" */ "@/views/Settlements/Settlements.vue"
                ),
        },
        meta: {
            authentication: false,
        },
    },

    {
        path: "/settlement/:id",
        name: "Settlement",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "Settlement" */ "@/views/Settlements/Settlement.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: false,
        },
    },

    {
        path: "/settlement-log/:id",
        name: "SettlementPreviewLog",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "SettlementPreviewLog" */ "@/views/Settlements/SettlementPreviewLog.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: false,
        },
    },

    {
        path: "/inactive-settlements",
        name: "InactiveSettlements",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "InactiveSettlements" */ "@/views/Settlements/InactiveSettlements.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/deleted-settlements",
        name: "DeletedSettlements",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "DeletedSettlements" */ "@/views/Settlements/DeletedSettlements.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/deactivated-settlements",
        name: "DeactivatedSettlements",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "DeactivatedSettlements" */ "@/views/Settlements/DeactivatedSettlements.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/settlement-create-edit/:id?",
        name: "settlementCreateEdit",
        beforeEnter: hasAuth,
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "settlementCreateEdit" */ "@/views/Settlements/SettlementCreateEdit.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: false,
        },
    },
];

export default settlementRoutes
