<template>
    <v-text-field
        v-model.trim="inputValue"
        required
        outlined
        hide-spin-buttons
        color="primary"
        :type="type"
        :dense="dense"
        :error-messages="errors"
        placeholder=" "
        persistent-placeholder
        :autocomplete="autofill"
        @keypress="emit($event)"
        @input="$v.inputValue.$touch(); emit($event)"
        @blur="$v.inputValue.$touch(); emit($event)"
    >
        <template v-slot:label>
            {{ label }} <span class="asterisk"></span>
        </template>
    </v-text-field>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
    name:   "GeneralInput",

    validations: {
        inputValue: {
            required
        }
    },

    props: {
        currentValue: {
            required: false,
            type: String,
            default: ""
        },
        label: {
            required: true,
            type: String
        },
        type: {
            required: false,
            type: String,
            default() {
                return "text"
            }
        },
        dense: {
            required: false,
            type: Boolean,
            default() {
                return false
            }
        }
    },

    data() {
        return {
            inputValue: ""
        }
    },

    computed: {
        errors() {
            const errors = [];
                if (!this.$v.inputValue.$dirty) return errors
                !this.$v.inputValue.required && errors.push(this.$i18n.t(`messages.error.required`))
                return errors
        },

        autofill() {
            if (this.value) {
                return "inputValue";
            }
            return "on"
        }
    },

    methods: {
        emit($event) {
            if (this.type == "number") {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if (keyCode < 48 || keyCode > 57) {
                    $event.preventDefault();
                }
            }
            this.$emit("update:inputValue", this.inputValue);
            this.$emit("update:isValid", this.$v.$invalid);
        },
    },

    created() {
        if(this.currentValue) {
            this.inputValue = this.currentValue
        }
    },

    beforeDestroy(){
        this.$emit("update:inputValue", null);
    }
}
</script>

<style lang="scss" scoped>

</style>