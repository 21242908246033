const setOta = ({ commit }, payload) => {
    commit("setOta", payload);
};

const setHasRegion = ({ commit }, payload) => {
    commit("setHasRegion", payload);
};

const setHasStorage = ({ commit }, payload) => {
    commit("setHasStorage", payload);
};

const setHasCollection = ({ commit }, payload) => {
    commit("setHasCollection", payload);
};

const setSed = ({ commit }, payload) => {
    commit("setSed", payload);
};

const setMunicipality = ({ commit }, payload) => {
    commit("setMunicipality", payload);
};

const setFoodCompany = ({ commit }, payload) => {
    commit("setFoodCompany", payload);
};

const setCompany = ({ commit }, payload) => {
    commit("setCompany", payload);
};

export default {
    setOta,
    setHasRegion,
    setHasStorage,
    setHasCollection,
    setSed,
    setMunicipality,
    setFoodCompany,
    setCompany
}