import store from "@/store";

import Navbar from "@/layout/Navbar.vue";
import Sidebar from "@/layout/Sidebar.vue";


/**
 *  beforeEnter Navigation guards 
 *  hasAuth
 */
 const hasAuth = (to, from, next) => {
    let isLoggedIn = store.getters["auth/isLoggedIn"];
    if (to.params.id && !isLoggedIn) {
        return next("/login");
    }
    next();
    
};

const collectorRoutes = [
    {
        path: "/collectors",
        name: "Collectors",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "Collectors" */ "@/views/Collectors/Collectors.vue"
                ),
        },
        meta: {
            authentication: false,
        },
    },

    {
        path: "/collector/:id",
        name: "Collector",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "Collector" */ "@/views/Collectors/Collector.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: false,
        },
    },

    {
        path: "/collector-log/:id",
        name: "CollectorPreviewLog",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "CollectorPreviewLog" */ "@/views/Collectors/CollectorPreviewLog.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: false,
        },
    },

    {
        path: "/dangerous-collector-create-edit/:id?",
        name: "DangerousCreateEdit",
        beforeEnter: hasAuth,
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "DangerousCreateEdit" */ "@/views/Collectors/DangerousCreateEdit.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: false,
        },
    },
    {
        path: "/not-dangerous-collector-create-edit/:id?",
        name: "NotDangerousCreateEdit",
        beforeEnter: hasAuth,
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "NotDangerousCreateEdit" */ "@/views/Collectors/NotDangerousCreateEdit.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: false,
        },
    },
];


export default collectorRoutes