<template>
    <v-overlay absolute :opacity="0.4">
        <v-progress-circular
            :size="40"
            :width="4"
            color="primary"
            indeterminate
        ></v-progress-circular>
    </v-overlay>
</template>

<script>
export default {
    name: "BaseLoader",
}
</script>

<style lang="scss" scoped>
</style>