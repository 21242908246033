<template>
    <v-text-field 
        v-model.trim="password"
        required 
        outlined
        :dense="dense"
        color="primary"
        background-color="background darken-1"
        :type="passwordField"
        :append-icon="passwordField == 'password' ? 'far fa-eye' : 'far fa-eye-slash'"
        :error-messages="errors"
        @click:append="revealPassword()"
        @input="$v.password.$touch(); emit()"
        @blur="$v.password.$touch(); emit()"
    >
        <template v-slot:label>
            {{ label }} <span class="asterisk"></span>
        </template>
    </v-text-field>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
export default {
    name:   "PasswordConfirmation",

    validations: {
        password: {
            required,
            minLength: minLength(3),
            sameAsPassword: sameAs("newPassword"),
        }
    },

    props: {
        label: {
            type:       String,
            required:   true,
        },
        newPassword: {
            type:       String,
            false:      true,
            default:    ""
        },
        dense: {
            required: false,
            type: Boolean,
            default() {
                return false
            }
        }
    },

    data() {
        return {
            password: "",
            passwordField: "password",
        }
    },

    computed: {
        errors() {
            const errors = [];
            if(!this.$v.password.$dirty) return errors
            !this.$v.password.sameAsPassword && errors.push(this.$i18n.t(`messages.error.sameAs`))
            !this.$v.password.minLength && errors.push(this.$i18n.t(`messages.error.minLenght`))
            return errors
        },
    },

    methods: {
        emit() {
            this.$emit("update:password", this.password);
            this.$emit("update:is-valid", this.$v.$invalid);
        },

        revealPassword() {            
            this.passwordField == "password" ? this.passwordField = "text": this.passwordField = "password";
        },
    },
}
</script>

<style lang="scss" scoped>

</style>