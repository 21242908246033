import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    isOta: false,
    hasRegion: false,
    hasStorage: false,
    hasCollection: false,
    hasSed: false,
    hasMunicipality: false,
    isFoodCompany: false,
    isCompany: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};