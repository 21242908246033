import Navbar from "@/layout/Navbar.vue";
import Sidebar from "@/layout/Sidebar.vue";

const libraryRoutes = [
    {
        path: "/library-categories",
        name: "LibraryCategories",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "LibraryCategories" */ "@/views/Library/LibraryCategories.vue"
                ),
        },
    },

    {
        path: "/library-categories-create-edit/:id?",
        name: "LibraryCategoryCreateEdit",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "LibraryCategoryCreateEdit" */ "@/views/Library/LibraryCategoryCreateEdit.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/library-articles",
        name: "LibraryArticles",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "LibraryArticles" */ "@/views/Library/LibraryArticles.vue"
                ),
        },
    },
    {
        path: "/library-article/:id?",
        name: "LibraryArticle",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "LibraryArticle" */ "@/views/Library/LibraryArticle.vue"
                ),
        },
        props: { main: true },
    },

    {
        path: "/library-create-edit/:id?",
        name: "LibraryCreateEdit",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "LibraryCreateEdit" */ "@/views/Library/LibraryCreateEdit.vue"
                ),
        },
        props: { main: true },
        meta: {
            authentication: false,
        },
    },
];

export default libraryRoutes