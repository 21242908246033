<template>
    <v-btn
        depressed
        outlined
        dense
        color="primary lighten-1"
        @click="$router.go(-1)"
    >
        <v-icon size="16" class="mr-2">
            fa-solid fa-chevron-left
        </v-icon>
            {{ $t(`general.returnBackBtn`) }}
    </v-btn>
</template>

<script>
export default {
    name: "BaseBackButton"
}
</script>