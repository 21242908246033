const isLoggedIn = state => !!state.token;
const getToken = state => state.token;
const getUserRole = state => state.userRole;
const getUserInfo = state => state.userInfo;

export default {
    isLoggedIn,
    getToken,
    getUserRole,
    getUserInfo
}