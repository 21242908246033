<template>
    <v-container
        fluid
        class="login"
    >
        <div class="overlay"></div>

        <div class="login__box shadow">
            <div class="login__header">
                <div class="mt-2 mb-4">
                    <h1 class="login-form-title">
                        {{ $t(`general.appTitle`) }}
                    </h1>
                </div>
            </div>

            <div class="login__content">
                <base-info-alert color="primary">
                    {{ $t(`auth.forgot.info`) }}
                </base-info-alert>
            </div>

            <div class="login__form mt-4">
                <v-form
                    ref="loginForm"
                    full-width
                >
                    <general-input
                        :input-value.sync="username"
                        :is-valid.sync="isUsernameValid"
                        :label="$t('auth.username')"
                    ></general-input>

                    <v-btn
                        block
                        depressed
                        large
                        color="primary"
                        type="submit"
                        :disabled="isValid"
                        :loading="forgotStatus_Pending"
                        @click.prevent="forgot()"
                    >
                        {{ $t("auth.forgot.forgotButton") }}
                    </v-btn>
                </v-form>
            </div>
            <div class="login__form-extra">
                <p class="ma-0">
                    {{ $t(`auth.forgot.loginRedirection`) }}
                </p>
                <v-btn
                    text
                    depressed
                    color="primary"
                    :to="{ name: 'Login' }"
                >
                    {{ $t("auth.forgot.loginRedirectionBtn") }}
                </v-btn>
            </div>
        </div>
        <v-dialog
            v-model="successDialogOpen"
            persistent
            max-width="500"
        >
            <v-card outlined>
                <v-card-title class="text-h5 d-flex flex-column">
                    {{ $t(`auth.forgot.dialog.title`) }}
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                    {{ dialogText }}
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        :loading="forgotStatus_Pending"
                        @click="confirmSuccess()"
                    >
                        Οκ
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { forgotPassword } from "@/api/authApi.js";

import GeneralInput from "@/components/General/FormInputs/GeneralInput.vue";
export default {
    name: "Forgot",

    components: {
        GeneralInput,
    },

    data() {
        return {
            username: "",
            isUsernameValid: true,

            successDialogOpen: false,
            dialogText: "",
            forgotStatus: apiStatus.Idle,
        };
    },

    computed: {
        isValid() {
            if (!this.isUsernameValid) {
                return false;
            }
            return true;
        },

        ...apiStatusComputed("forgotStatus"),
    },

    methods: {
        async forgot() {
            this.forgotStatus = apiStatus.Pending;

            const payload = {
                username: this.username,
            };

            const { response, error } = await withAsync(forgotPassword, payload);

            if (error) {
                this.forgotStatus = apiStatus.Error;
                return;
            }

            this.forgotStatus = apiStatus.Success;
            this.successDialogOpen = true;
            this.dialogText = response.data.metadata.response_message;
        },

        confirmSuccess() {
            this.successDialogOpen = false;
            this.$router.push({ name: "Login" });
        },
    },

    created() {
        this.apiStatus = apiStatus;
    },
};
</script>

<style lang="scss" scoped>
h1 {
    line-height: 1em;
}

h1 span {
    display: block;
    text-transform: initial;
}

span.subtitle {
    color: var(--v-black-base);
    display: block;
    font-size: 1.2rem;
}
.header {
    display: flex;
    align-items: center;
}

.logo img {
    height: 90px;
    object-fit: contain;
}
.login--wrapper-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.login-form-title {
    color: var(--v-primary-base);
}

.forgot-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.2em;
}
</style>
