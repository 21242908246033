require("./overrides.scss");
export default {
    theme: {
        themes: {
            light: {
                primary: "#0065b3",
                secondary: "#008dcd",
                accent: "#00b1c6",
                info: "#a4abbd",
                success: "#8ce983",
                warning: "#fd9644",
                error: "#eb4d4b",
                sidenav: "#304156",
                textDark: "#2d3436"
            },
        },
    },
};
