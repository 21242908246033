<template>
    <v-text-field 
        v-model.trim="password"
        :required="isValueRequired" 
        outlined
        :dense="dense"
        color="primary"
        :type="passwordField"
        :append-icon="passwordField == 'password' ? 'far fa-eye' : 'far fa-eye-slash'"
        :error-messages="passwordErrors"
        :autocomplete="autofill"
        placeholder=" "
        persistent-placeholder
        @click:append="revealPassword()"
        @input="$v.password.$touch(); emit()"
        @blur="
            $v.password.$touch();
            emit();
        "
    >
        <template v-slot:label>
            {{ $t(`form.fields.${formItem.label}`) }} <span v-if="isValueRequired" class="asterisk"></span>
        </template>
    </v-text-field>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
export default {
    name:   "PasswordInput",

    validations() {
        if (this.isValueRequired) {
            return {
                password: {
                    required,
                    minLength: minLength(3)
                }
            };
        } else {
            return {
                password: {
                    required: false,
                    minLength: minLength(3)
                }
            };
        }
    },
       

    props: {
        value: {
            required: true,
        },
        formItem: {
            required: true,
            type: Object,
        },
        dense: {
            required: false,
            type: Boolean,
            default() {
                return false
            }
        },
    },

    data() {
        return {
            password: "",
            passwordField: "password",
        }
    },

    computed: {
        passwordErrors() {
            const errors = [];
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push(this.$i18n.t(`messages.error.required`))
            !this.$v.password.minLength && errors.push(this.$i18n.t(`messages.error.minLenght`, {numMin: 3}))
            return errors
        },
        isValueRequired() {
            if (this.formItem?.required) {
                return true;
            }
            return false;
        },

        autofill() {
            if("autofocus" in this.formItem && !this.formItem.autofocus){
                return "new-password"
            } 
            if (this.value) {
                return "password";
            }
            return "on"
        }
    },

    methods: {
        emit() {
            this.$emit("input", this.password);
            this.$emit("update:isValid", this.$v.$invalid);
        },

        revealPassword() {            
            this.passwordField == "password" ? this.passwordField = "text": this.passwordField = "password";
        },
    },

    watch: {
        value: {
            handler() {
                this.password = this.value;
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss" scoped>

</style>