const setToken = ( state, data ) => {
    state.token = data;
};

const removeToken = ( state ) => {
    state.token = "";
};

const setUserRole = ( state, data ) => {
    state.userRole = data;
};

const removeRole = ( state ) => {
    state.userRole = "";
};

const setUserInfo = ( state, data ) => {
    state.userInfo = data;
};

const removeUserInfo = ( state ) => {
    state.userInfo = {};
};

export default {
    setToken,
    setUserRole,
    removeToken,
    removeRole,
    setUserInfo,
    removeUserInfo
}