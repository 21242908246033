<template>
    <div class="table__heading">
        <v-icon
            size="20"
            color="primary lighten-1"
            class="mr-2"
        >
            <slot name="icon"></slot>
        </v-icon>
        <h3 class="table-title">
            <slot name="title"></slot>
        </h3>
    </div>
</template>

<script>
export default {
    name: "BaseTableHeading",
}
</script>

<style lang="scss" scoped>
.table__heading {
    display: flex;
    .table-title {
        font-size: 1.3em;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: 0.02em;
        color: #535c68;
    }
}
</style>