<template>
    <div class="page__heading d-flex">
        <slot name="back"></slot>
        <slot name="slash"></slot>
        <div>
            <h1 class="primary--text">
                <slot name="heading"></slot>
            </h1>
            <slot name="subHeading"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseHeading",
};
</script>

<style lang="scss" scoped>
.page__heading {
    margin-bottom: 2em;
}
</style>
