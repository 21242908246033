import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import globals from "./modules/globals";
import formSchema from "./modules/formSchema";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        globals,
        formSchema,
    },
});
