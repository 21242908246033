const getOta = state => state.isOta;
const getHasRegion = state => state.hasRegion;
const getHasStorage = state => state.hasStorage;
const getHasCollection = state => state.hasCollection;
const getSed = state => state.hasSed;
const getMunicipality = state => state.hasMunicipality;
const getFoodCompany = state => state.isFoodCompany;
const getCompany = state => state.isCompany;

export default {
    getOta,
    getHasRegion,
    getHasStorage,
    getHasCollection,
    getSed,
    getMunicipality,
    getFoodCompany,
    getCompany
}