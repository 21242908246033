import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    token: localStorage.getItem("hma_extention_token") || "",
    userRole: localStorage.getItem("hma_user_role") || "",
    userInfo: JSON.parse(localStorage.getItem("hma_user_info")) || {},
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};