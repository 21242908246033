<template>
    <div>
        <router-view name="topbar"></router-view>

        <router-view name="sidebar"></router-view>

        <v-main class="mt-5 mx-0 mx-md-4">
            <v-container>
                <transition name="fade-transform" mode="out-in">
                    <router-view name="main"></router-view>
                </transition>
            </v-container>
        </v-main>
    </div>
</template>

<script>
export default {
    name: "Layout",

}
</script>