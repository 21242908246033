import Navbar from "@/layout/Navbar.vue";
import Sidebar from "@/layout/Sidebar.vue";

const reportsRoutes = [
    {
        path: "/waste-register-reports",
        name: "wasteRegisterReports",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "wasteRegisterReports" */ "@/views/Reports/WasteRegisterReports.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },
    {
        path: "/food-waste-reports",
        name: "foodWasteReports",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "FoodWasteReports" */ "@/views/FoodWasteReports.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },
    {
        path: "/report-integrity",
        name: "ReportIntegrity",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "ReportIntegrity" */ "@/views/ReportIntegrity.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/registry-search",
        name: "RegistrySearch",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "RegistrySearch" */ "@/views/RegistrySearch.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/aggregated-search",
        name: "AggregatedSearchForm",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "AggregatedSearchForm" */ "@/views/AggregatedSearchForm.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/collect-transport",
        name: "CollectTransport",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "CollectTransport" */ "@/views/CollectTransport.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/business-relations",
        name: "BusinessRelations",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "BusinessRelations" */ "@/views/BusinessRelations.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/unsubmitted-statements",
        name: "UnsubmittedWasteReports",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "UnsubmittedWasteReports" */ "@/views/UnsubmittedWasteReports.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },

    {
        path: "/zero-waste-statements",
        name: "ZeroWasteReports",
        components: {
            topbar: Navbar,
            sidebar: Sidebar,
            main: () =>
                import(
                    /* webpackChunkName: "ZeroWasteReports" */ "@/views/ZeroWasteReports.vue"
                ),
        },
        meta: {
            authentication: true,
        },
    },
]

export default reportsRoutes