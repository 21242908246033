<template>
    <v-card
        :loading="loading"
        class="shadow pa-2 pa-sm-6"
    >
        <slot></slot>
    </v-card>
</template>

<script>
export default {
    name:   "BaseCard",

    props: {
        loading: {
            required: false,
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.shadow {
    box-shadow: 0px 0px 20px rgba(10, 52, 67, 0.25) !important;
    border-radius: 0.6em !important;
}
</style>